/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/******************************************
 *  Author : Suraj Sharma
 *  Created On : Sun Mar 07 2021
 *  File : TransactionTable.jsx
 *******************************************/
import React from 'react';
import PropTypes from 'prop-types';
import Data from './transaction.json';
import styles from './TransactionTable.module.css';

const TransactionTable = ({transactions, onSelectTransaction, ...props}) => {
    const isValidTransactions = Object.keys(transactions.list).length > 0;
    if(!isValidTransactions) {
        return (
            <div>
                <span>Not Valid!</span>
            </div>
        )
    }

    /**
     * NOTE:There can be many transactions for a given tree count
     * so we need to have pagination too.
     * We will display 20 records in one page
     */
    // const retrieve = 'hello world';
    const itemsPerPage = 10;
    const [state, setState] = React.useState({
        isLoading: true,
        totalItemsInTransactions: [],
        currentPagination: 1,
        onEndReached: false
    });

    React.useEffect(()=>{
        initializeTransactions([...transactions.list])
        .then((res)=>{
            setState({
                ...state,
                isLoading: false,
                totalItemsInTransactions: res
            })
        })
        .catch((err)=>{
            console.log(err);
        })
    }, [transactions]);

    const initializeTransactions = (apiTransactions) => new Promise((resolve, reject) => {
    
        const dateFormatedTransactions = apiTransactions;

        dateFormatedTransactions.map((item, index)=>{
            
            const {transaction_system, createdAt, created_at} = item;
            const dateOfCreation = new Date(createdAt || created_at);
            // console.log(dateOfCreation)
            if(transaction_system === 'bespoke' || transaction_system === 'Online Purchase'){
                const checkDate = createdAt || created_at;
                const check = isIsoDate(checkDate);
                if(check==false) {
                    const newdateOfCreation = toIsoStringUTC(dateOfCreation);
                    item.sortingDate = newdateOfCreation;
                }
                else{
                    item.sortingDate = checkDate;
                }
            }
            else{
                item.sortingDate = createdAt || created_at;
            }
        });
        // sort transactions by date
        const dateSortedTransactions = dateFormatedTransactions.sort((a,b)=> 
            new Date(b.sortingDate) - new Date(a.sortingDate)
        );
        resolve(dateSortedTransactions);
    });

    const dateToTwelveHour = (date) => {
        if(Number.isNaN(date.getUTCMinutes())) return 'NA';
        // else if valid timestamp

        let hours = date.getUTCHours();
        let returnTime = '';        
        const minutes = date.getUTCMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const year = date.getUTCFullYear();
        // month as 2 digits (MM)
        const month = (`0${  date.getUTCMonth() + 1}`).slice(-2);
        // date as 2 digits (DD)
        const day = (`0${  date.getUTCDate()}`).slice(-2);
        returnTime = `${day}/${month}/${year}`
        
        // append time to the date
        hours = hours % 12 ? hours % 12 : 12; // the hour '0' should be '12'            
        const prependedMinute = minutes.toString().length === 1 ? `0${minutes}` : minutes;
        const prependedHour = hours.toString().length === 1 ? `0${hours}` : hours;
        returnTime += ` @${prependedHour}:${prependedMinute} ${ampm}`

        return returnTime;
    }

    function toIsoStringUTC(date) {
        var tzo = 0,
            dif = tzo >= 0 ? '+' : '-',
            pad = function(num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };
      
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(tzo / 60) +
            ':' + pad(tzo % 60);
    }

    function isIsoDate(str) {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
        var d = new Date(str); 
        return d.toISOString()===str;
    }

    const onLocalSelectTransaction = (data) => {
        const {transaction_system, transaction_id, guid} = data;
        const treeCount = data?.remaining_tree_count || data?.tree_count || data?.count;
        onSelectTransaction(transaction_system, transaction_id || guid, treeCount);
    }

    const renderBody = () => {
        // eslint-disable-next-line prefer-const
        let {currentPagination, totalItemsInTransactions} = state;
        const startIndex = (currentPagination - 1 ) * itemsPerPage;
        const itemsToBeDisplayed = totalItemsInTransactions.slice(startIndex, startIndex + itemsPerPage);
        if(itemsToBeDisplayed.length === 0) return(
            <div>
                <span>No available Transactions</span>
            </div>
        );
        return (
            itemsToBeDisplayed.map((item, index)=>{
                const key = `${index}key`;
                const {transaction_system, restaurant_name, company_name, createdAt, created_at} = item;
                // const isRestaurant = transaction_system === 'restaurant';
                const numberOfTrees =  item?.remaining_tree_count || item?.tree_count || item?.count;
                let dateOfCreation = new Date(createdAt || created_at);
                if(transaction_system === 'bespoke' || transaction_system === 'Online Purchase'){
                    let checkDate = createdAt || created_at;
                    let check = isIsoDate(checkDate);
                    if(check==false) {
                        dateOfCreation = toIsoStringUTC(dateOfCreation);
                        dateOfCreation = new Date(dateOfCreation);
                    }
                }
                const returnTime = dateToTwelveHour(dateOfCreation);
                return(
                    <div key={ key } className={styles.listItemRow}>
                        <div className={styles.multiTransactionTableRow}>
                            <span style={ {flex:0.2} } className={styles.listItem}>{numberOfTrees}</span>
                            <span style={ {flex:0.3} } className={styles.listItem}>
                            {
                                transaction_system === 'restaurant' && 'Restaurant' ||
                                transaction_system === 'sustainable_meeting' && 'Meeting' ||
                                transaction_system === 'bespoke' && 'Online Purchase'
                            }
                            </span>
                            <span style={ {flex:0.2} } className={styles.listItem} >
                                {returnTime}
                            </span>
                            <span style={ {flex:0.3, wordBreak: "break-all"} } className={`${styles.listItem} ${styles.overflow}`}>
                            {
                                restaurant_name || 
                                company_name ||
                                'NIL'
                                // transaction_system === 'bespoke' && 'Krispy Kreme'
                            }
                            <button 
                                type="button" 
                                style={ {wordBreak: "normal"} }
                                className={styles.transactionSelectButton} 
                                onClick={ ()=>onLocalSelectTransaction(item) }
                            >
                                Select
                            </button>
                            </span>
                        </div>
                    </div>
                )
            }
        ));
    }

    const onUpdatePagination = (direction = 'next', pageValue = -1) => {
        const newState = {...state};
        const {currentPagination, totalItemsInTransactions} = newState;        

        if(pageValue > -1) newState.currentPagination = pageValue;
        else if(direction === 'next') {
            // we have also find when end of transactions is reached
            // for that we calculate the number of transactions already rendered
            // against total number of available transactions
            // already rendered transaction counts
            const preRenderedTransactionCount = (currentPagination) * itemsPerPage;
            const remainingTransactionsToBeRendered = totalItemsInTransactions.length - preRenderedTransactionCount;
            if(remainingTransactionsToBeRendered <= itemsPerPage){
                newState.onEndReached = true;
            }
            else{
                newState.onEndReached = false;
            }
            newState.currentPagination= currentPagination + 1;
        }
        else {
            newState.currentPagination= currentPagination - 1;
            newState.onEndReached = false;
        };
        setState(newState);
    }

    const renderPagination = () => {
        // first find the total number of displayable pages
        const {currentPagination, onEndReached, totalItemsInTransactions} = state;
        const pages = Math.ceil(totalItemsInTransactions.length / itemsPerPage);
        const isPreviousButtonDisabled = currentPagination <= 1;
        // eslint-disable-next-line prefer-const
        let isNextButtonDisabled = onEndReached;
        // for next page button to be active we should have
        // items remaining which can be displayed
        return (
            pages > 1 && (
                <div className={styles.pageButtonContainer}>
                    <button 
                        type="button" 
                        onClick={ ()=>onUpdatePagination('prev') } 
                        className={ `${styles.prevPage} ${isPreviousButtonDisabled && styles.disabledButton}` }
                        disabled={ isPreviousButtonDisabled }
                    >
                        Previous
                    </button>
                    <span className={styles.pageNo}>
                        {currentPagination} / {pages}
                    </span>
                    <button 
                        type="button" 
                        onClick={ ()=>onUpdatePagination('next') } 
                        className={ `${styles.nextPage} ${isNextButtonDisabled && styles.disabledButton}` }
                        disabled={ isNextButtonDisabled }
                    >
                        Next
                    </button>   
                </div>
            )
        );
    }

    if(state.isLoading) return null;
    return (
        <div className={styles.multiTransactionTable}>
            <div className={`${styles.multiTransactionTableRow} ${styles.headerSeparator}`}>
                <span style={ {flex:0.2} } className={styles.listItem}>No. of trees</span>
                <span style={ {flex:0.3} } className={styles.listItem}>Occasion</span>
                <span style={ {flex:0.2} } className={styles.listItem}>Time/Date</span>
                <span style={ {flex:0.3} } className={styles.listItem}>Company</span>
            </div>
            {renderBody()}
            {
                // pagination
                renderPagination()
            }
        </div>
    );
}

TransactionTable.propTypes = {
    transactions: PropTypes.instanceOf(Object),
    onSelectTransaction: PropTypes.func
}

TransactionTable.defaultProps = {
    transactions: Data,
    onSelectTransaction: ()=>{}
}

export default TransactionTable;